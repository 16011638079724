<template>
  <div class="app-container">
    <el-row>
      <span style="color: gray;font-size: 12px;">备注：鼠标右键点击下载二维码</span>
    </el-row>
    <el-row>
      <img style="max-width: 100%;" :src="form.qrcode"  class="avatar"  @click="handlePictureEnlarge(form.qrcode)"  >
    </el-row>
    <el-dialog v-model="dialogVisiblePic" title="企业信息二维码">
      <el-row>
        <span style="color: gray;font-size: 12px;">备注：鼠标右键点击下载二维码</span>
      </el-row>
      <img :src="enlargedImageUrl" alt="企业信息二维码" style="max-width: 100%; display: block; margin: auto;">
    </el-dialog>
  </div>


</template>

<script>

export default {
  name: 'OwnCompanyQrcode',
  data() {
    return {
      form: {},

      dialogVisiblePic: false,
      enlargedImageUrl: '',

    }
  },
  created() {
    let str = sessionStorage.getItem("user") || "{}"
    this.form = JSON.parse(str);
  },
  methods: {
    //放大预览
    handlePictureEnlarge(imageUrl) {
      this.enlargedImageUrl = imageUrl;
      this.dialogVisiblePic = true;
    },

  }
}
</script>
